import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { Gallery } from 'app/components/Gallery'
import { Hero } from 'app/components/Hero'
import { Info } from 'app/components/Info'
import { Paragraph } from 'app/components/Paragraph'
import { SEO } from 'app/components/SEO'
import { ZigZagList } from 'app/components/ZigZag'
import { Footer } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { StructuredData } from 'app/containers/StructuredData'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id?: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function RestaurantPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      <GoogleAnalytics />
      {pageContext.headerProps ? (
        <Header
          pageID={pageContext.id}
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...pageContext.headerProps}
        />
      ) : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.paragraphProps ? (
        <Paragraph {...context.paragraphProps} />
      ) : null}
      {context.zigZagProps ? <ZigZagList {...context.zigZagProps} /> : null}
      {context.infoProps ? <Info {...context.infoProps} /> : null}
      {context.galleryProps ? <Gallery {...context.galleryProps} /> : null}
      {context.bannerCTAProps ? (
        <BannerCTA {...context.bannerCTAProps} />
      ) : null}
      <Footer
        languageCode={pageContext.languageCode}
        languagePrefix={pageContext.languagePrefix || 'it'}
      />
      <StructuredData languageCode={pageContext.languageCode} />
    </Container>
  )
})

const Container = styled.main``
